import * as React from 'react';

import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core';

import { AppButtons, ThemedWrapper } from '../components/ui';
import '../components/ui/sidebar/layout.css';

interface Props {
  header?: string
  body?: string
}

const GetApp = ({ header = '', body = 'Get the mobile app now!' }: Props) => {
  return (
    <ThemedWrapper>
      <Container>
        <Grid container alignItems="center" direction="column" spacing={1}>
          {header && (
            <Grid item xs={12}>
              <Typography variant="h6">{header}</Typography>
            </Grid>
          )}
          <Grid item>
            <Typography variant="body1">{body}</Typography>
          </Grid>
          <Grid item>
            <AppButtons/>
          </Grid>
        </Grid>
      </Container>
    </ThemedWrapper>
  );
};

export default GetApp;
